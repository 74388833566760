<template>
  <div class="news-info">
    <head>
      <title>{{ data.name }}</title>
    </head>
    <div class="container py-5">
      <div class="bg-whitesmoke py-5">
        <div class="row row-cols-auto w-100 p-3 my-3">
          <div class="col-3">
            <button
              class="btn btn-outline-warning w-50 text-dark p-2"
              @click="page_type = 0"
            >
              صور المشروع
            </button>
          </div>
          <div class="col-3">
            <button
              class="btn btn-outline-warning w-50 text-dark p-2"
              @click="page_type = 1"
            >
              مصاريف
            </button>
          </div>
          <div class="col-3">
            <button
              class="btn btn-outline-warning w-50 text-dark p-2"
              @click="page_type = 2"
            >
              الشهادات
            </button>
          </div>
          <div class="col-3">
            <button
              class="btn btn-outline-warning w-50 text-dark p-2"
              @click="page_type = 3"
            >
              مباشر
            </button>
          </div>
        </div>
        <div class="grid-2" v-if="page_type == 0">
          <div class="photos-videos">
            <div class="abs-buttons">
              <div
                class="photos btn-style"
                :style="{ color: type == 'photo' ? '#c39a3e' : '' }"
                @click="type = 'photo'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M11.943 1.25h.114c2.309 0 4.118 0 5.53.19c1.444.194 2.584.6 3.479 1.494c.895.895 1.3 2.035 1.494 3.48c.19 1.411.19 3.22.19 5.529v.088c0 1.909 0 3.471-.104 4.743c-.104 1.28-.317 2.347-.795 3.235c-.21.391-.47.742-.785 1.057c-.895.895-2.035 1.3-3.48 1.494c-1.411.19-3.22.19-5.529.19h-.114c-2.309 0-4.118 0-5.53-.19c-1.444-.194-2.584-.6-3.479-1.494c-.793-.793-1.203-1.78-1.42-3.006c-.215-1.203-.254-2.7-.262-4.558c-.002-.473-.002-.973-.002-1.501v-.058c0-2.309 0-4.118.19-5.53c.194-1.444.6-2.584 1.494-3.479c.895-.895 2.035-1.3 3.48-1.494c1.411-.19 3.22-.19 5.529-.19m-5.33 1.676c-1.278.172-2.049.5-2.618 1.069c-.57.57-.897 1.34-1.069 2.619c-.174 1.3-.176 3.008-.176 5.386v.844l1.001-.876a2.3 2.3 0 0 1 3.141.104l4.29 4.29a2 2 0 0 0 2.564.222l.298-.21a3 3 0 0 1 3.732.225l2.83 2.547c.286-.598.455-1.384.545-2.493c.098-1.205.099-2.707.099-4.653c0-2.378-.002-4.086-.176-5.386c-.172-1.279-.5-2.05-1.069-2.62c-.57-.569-1.34-.896-2.619-1.068c-1.3-.174-3.008-.176-5.386-.176s-4.086.002-5.386.176"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                v-if="this.data.videos !== null"
                class="videos btn-style"
                :style="{ color: type == 'video' ? '#c39a3e' : '' }"
                @click="type = 'video'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="m164.44 105.34l-48-32A8 8 0 0 0 104 80v64a8 8 0 0 0 12.44 6.66l48-32a8 8 0 0 0 0-13.32M120 129.05V95l25.58 17ZM216 40H40a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16m0 128H40V56h176zm16 40a8 8 0 0 1-8 8H32a8 8 0 0 1 0-16h192a8 8 0 0 1 8 8"
                  />
                </svg>
              </div>
            </div>
            <div class="myimgs-container" v-if="type == 'photo'">
              <div class="my-img-container">
                <img
                  :src="$store.state.server_images + src"
                  alt=""
                  clsas="img-bg-news"
                  :style="{
                    height: 'fit-content',
                    width: '500px',
                  }"
                />
                <div class="show-hide" @click="ShowHide()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="white"
                      d="M16 0H9.5L12 2.5l-3 3L10.5 7l3-3L16 6.5zm0 16V9.5L13.5 12l-3-3L9 10.5l3 3L9.5 16zM0 16h6.5L4 13.5l3-3L5.5 9l-3 3L0 9.5zM0 0v6.5L2.5 4l3 3L7 5.5l-3-3L6.5 0z"
                    />
                  </svg>
                </div>
              </div>
              <!-- img changer -->
              <div class="grid-3">
                <img
                  class="imgs"
                  :src="$store.state.server_images + data.image"
                  alt=""
                  clsas="img-bg-news"
                  @click="src = data.image"
                />
                <img
                  class="imgs"
                  v-for="(item, index) in data.images"
                  :key="index"
                  :src="$store.state.server_images + item.image"
                  alt=""
                  clsas="img-bg-news"
                  @click="src = item.image"
                />
              </div>
            </div>
            <div class="myvideo-container" v-if="type == 'video'">
              <div class="my-vide-container">
                <video
                  class="my-video"
                  controls
                  @contextmenu.prevent
                  controlsList="nodownload"
                >
                  <source
                    :src="$store.state.server_images + videoSrc"
                    type="video/mp4"
                  />
                  Your browser does not support HTML5 video.
                </video>
                <div class="grid-3">
                  <div
                    class="video-card"
                    v-for="(item, index) in data.videos"
                    :key="index"
                    @click="ChangeVideo(item.video)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" stroke="white">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.211 11.106L9.737 7.868A1.2 1.2 0 0 0 8 8.942v6.116a1.2 1.2 0 0 0 1.737 1.074l6.474-3.238a1 1 0 0 0 0-1.788"
                        />
                        <circle cx="12" cy="12" r="9" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content text-end">
            <h1>{{ data.name }}</h1>
            <h4>{{ data.text }}</h4>
          </div>
        </div>
        <div class="row row-cols-auto w-100 p-3 my-3" v-if="page_type == 1">
          <div class="col-12">
            <div class="myimgs-container">
              <div class="my-img-container">
                <img
                  :src="src_certificate"
                  alt=""
                  clsas="img-bg-news"
                  :style="{
                    height: 'fit-content',
                    width: '500px',
                  }"
                />
                <div class="show-hide" @click="ShowHide2()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="white"
                      d="M16 0H9.5L12 2.5l-3 3L10.5 7l3-3L16 6.5zm0 16V9.5L13.5 12l-3-3L9 10.5l3 3L9.5 16zM0 16h6.5L4 13.5l3-3L5.5 9l-3 3L0 9.5zM0 0v6.5L2.5 4l3 3L7 5.5l-3-3L6.5 0z"
                    />
                  </svg>
                </div>
              </div>
              <!-- img changer -->
              <div class="grid-3">
                <img
                  class="imgs"
                  v-for="(item, index) in photos"
                  :key="index"
                  :src="item"
                  alt=""
                  clsas="img-bg-news"
                  @click="src_certificate = item"
                />
              </div>
            </div>
            <div class="myvideo-container" v-if="type == 'video'">
              <div class="my-vide-container">
                <video
                  class="my-video"
                  controls
                  @contextmenu.prevent
                  controlsList="nodownload"
                >
                  <source
                    :src="$store.state.server_images + videoSrc"
                    type="video/mp4"
                  />
                  Your browser does not support HTML5 video.
                </video>
                <div class="grid-3">
                  <div
                    class="video-card"
                    v-for="(item, index) in data.videos"
                    :key="index"
                    @click="ChangeVideo(item.video)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" stroke="white">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.211 11.106L9.737 7.868A1.2 1.2 0 0 0 8 8.942v6.116a1.2 1.2 0 0 0 1.737 1.074l6.474-3.238a1 1 0 0 0 0-1.788"
                        />
                        <circle cx="12" cy="12" r="9" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-auto w-100 p-3 my-3" v-if="page_type == 2">
          <div class="col-12" v-for="(item, index) in excel" :key="index">
            <a
              :href="item.file"
              class="btn btn-outline-dark w-100 py-4"
              download
            >
              Download {{ item.name }}
            </a>
          </div>
        </div>
        <div class="row row-cols-auto w-100 p-3 my-3" v-if="page_type == 3">
          <div class="col-12 text-dark"><h1>قادم قريباً...</h1></div>
        </div>
      </div>
    </div>
    <div class="popup" id="popup" style="opacity: 0; display: none">
      <span class="close" @click="ShowHide()">X</span>
      <div class="popup-content">
        <img :src="$store.state.server_images + src" alt="" />
      </div>
    </div>
    <div class="popup" id="popup2" style="opacity: 0; display: none">
      <span class="close" @click="ShowHide2()">X</span>
      <div class="popup-content">
        <img :src="src_certificate" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";

export default {
  name: "news-info",
  data() {
    return {
      type: "photo",
      src: null,
      src_certificate: null,
      videoSrc: null,
      data: {},
      page_type: 2,
      excel: [
        {
          file: "/Excel/Laptops.xlsx",
          name: "Laptops.xlsx",
        },
      ],
      photos: [
        require("@/assets/TestImages/AboutUs/img1.jpg"),
        require("@/assets/TestImages/AboutUs/img2.jpg"),
      ],
    };
  },
  mounted() {
    this.src_certificate = this.photos[0];
    this.getData();
  },
  methods: {
    async ChangeVideo(src) {
      window.localStorage.setItem("video", src);
      location.reload();
    },
    async GetVideo() {
      this.videoSrc = window.localStorage.getItem("video");
      this.type = "video";
      localStorage.removeItem("video");
    },
    ShowHide() {
      const element = document.getElementById("popup");
      if (element.style.opacity == 0 || element.style.opacity === "") {
        element.style.opacity = 1;
        element.style.display = "block";
      } else {
        element.style.opacity = 0;
        element.style.display = "none";
      }
    },
    ShowHide2() {
      const element = document.getElementById("popup2");
      if (element.style.opacity == 0 || element.style.opacity === "") {
        element.style.opacity = 1;
        element.style.display = "block";
      } else {
        element.style.opacity = 0;
        element.style.display = "none";
      }
    },
    getData() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      axios
        .get(store.state.server + "show-news/" + id)
        .then((response) => {
          this.data = response.data;
          this.src = this.data.image;
          if (window.localStorage.getItem("video") !== null) {
            this.GetVideo();
          } else {
            if (this.data.videos !== null)
              this.videoSrc = this.data.videos[0].video;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.grid-2 {
  display: grid;
  grid-template-columns: auto auto;
}

.photos-videos {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abs-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: whitesmoke;
  padding: 10px 30px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.btn-style {
  margin: 0px 10px 0 10px;
  cursor: pointer;
}

.my-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.myvideo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.my-video {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 500px;
  height: 300px;
  background-color: black;
}

.grid-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
}

.imgs {
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 30px 20px 0px 20px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.video-card {
  height: 100px;
  width: 100px;
  background-color: black;
  margin: 30px 20px 0px 20px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgs:hover {
  transform: translateY(-10px);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.video-card:hover {
  transform: translateY(-10px);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.content {
  width: 500px;
}

.content > h1 {
  color: #c39a3e;
}

.popup {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.181);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
}

.popup-content {
  margin-top: 30px;
  position: relative;
  width: 100dvw;
  height: 90dvh;
}

.popup-content > img {
  height: 100%;
  width: fit-content;
}

.show-hide {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(195, 194, 194, 0.5);
  padding: 10px;
  cursor: pointer;
}

.close {
  position: fixed;
  z-index: 101;
  top: 10px;
  left: 5px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px;
  text-align: center;
  background-color: gray;
  color: white;
  cursor: pointer;
  border-radius: 50px;
}
</style>
